import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";

// APIs
import getDataBreaches from "api/dataBreach/getDataBreaches";

// MUI
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";

const CustomTooltip = (columns) => {
  // eslint-disable-next-line react/destructuring-assignment
  const arr = columns.props;

  return (
    <Tooltip
      title={arr.map((item, j) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={j}
          style={{
            fontSize: "14px",
            background: "white",
            borderRadius: "35px",
            textAlign: "center",
            marginBottom: "3px",
            color: "black",
            padding: "1px 5px",
          }}
          background="white"
        >
          {item}
        </div>
      ))}
    >
      <IconButton>
        <Icon fontSize="small">info</Icon>
      </IconButton>
    </Tooltip>
  );
};

const DataBreach = () => {
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      const { results } = await getDataBreaches();
      setData(results);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Title */}
      <h2 className="page-title">{t("sidenav.data_breach")}</h2>

      {/* Page description */}
      <p style={{ fontSize: "small" }}>{t("dataBreach.page_description")}</p>

      {/* Table */}
      <div className="table_users_list" style={{ maxWidth: "900px", marginTop: "20px" }}>
        <table className="table-content" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>{t("dataBreach.date")}</th>
              <th style={{ textAlign: "center" }}>{t("dataBreach.threat_actor_name")}</th>
              <th style={{ textAlign: "center" }}>{t("dataBreach.description")}</th>
              <th style={{ textAlign: "center" }}>{t("dataBreach.victim_nation")}</th>
              <th style={{ textAlign: "center" }}>{t("dataBreach.columns")}</th>
              <th style={{ textAlign: "center" }}>{t("dataBreach.details")}</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0
              ? data.map((breach) => (
                  <tr key={breach.id}>
                    <td>{moment(breach.date).format("DD/MM/YYYY")}</td>
                    <td>{breach.threatActor.name}</td>
                    <td style={{ maxWidth: "300px" }}>{breach.shortDescription}</td>
                    <td>{breach.victim?.country || "N/A"}</td>
                    <td>
                      {breach.columns.length}

                      <CustomTooltip props={breach.columns} />
                    </td>
                    <td>
                      <Link to={`/dataBreaches/${breach.id}`}>{t("dataBreach.details")}</Link>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default DataBreach;
