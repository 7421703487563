/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { I18nextProvider /* , Translation */ } from "react-i18next";

import getTokens from "api/getTokens";
import usersPresent from "api/users/usersPresent";
import refreshTokens from "api/auth/refreshTokens";
// import addLog from "api/logs/addLog";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
// eslint-disable-next-line
import theme from "assets/theme";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import routesAdmin from "routesAdmin";

import TokenExpiredPopup from "components/TokenExpiredPopup";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setUser,
  setCustomers,
  setAllStatistics,
} from "context";

import getClientsByIds from "api/clients/getClientsByIds";
import getUser from "api/users/getUser";
import getAllStatistics from "api/statistics/getAllStatistics";
import counterStealers from "api/statistics/counterStealers";

// Images
import brandDark from "assets/images/logo-light.png";
import brandSmall from "assets/images/loghetto_DEFENDA.png";
import DemoAlert from "utilities/DemoAlert";
import i18next from "./language/i18next";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, user, allStatistics, customers } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const [userRoutes, setUserRoutes] = useState(routes);
  const [demoAlert, setDemoAlert] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    const tokens = getTokens();
    if (
      window.location.href.endsWith("/login") ||
      window.location.href.endsWith("/forgotPassword") ||
      window.location.href.includes("/resetPassword") ||
      window.location.href.includes("/confirmEmail")
    ) {
      setIsPageReady(true);
    }

    if (
      (
        window.location.href.endsWith("/login") ||
        window.location.href.endsWith("/forgotPassword") ||
        window.location.href.includes("/resetPassword")
      ) &&
      tokens.access &&
      tokens.refresh
    ) {
      window.location.href = "/";
    } else if (
      !window.location.href.endsWith("/login") &&
      !window.location.href.endsWith("/forgotPassword") &&
      !window.location.href.includes("/resetPassword") &&
      !tokens.access &&
      !tokens.refresh
    ) {
      window.location.href = "/login";
    } else if (tokens.userid) {
      
      // console.trace("Calling refreshTokens()");

      refreshTokens().then((responseJson) => {
        if (responseJson && responseJson.access && responseJson.refresh) {
          getUser(tokens.userid, responseJson.access)
            .then((res) => {
              if (res?.language !== "it")
                changeLanguage(res.language);

              if (!user || !user.id)
                setUser(dispatch, res);

              if (!tokens.mfa && !window.location.href.endsWith("/mfa")) {
                window.location.href = "/mfa";
              } else {
                setIsPageReady(true);
                if (res && res.clients && res.clients.length > 0 && tokens.mfa) {
                  getClientsByIds({ ids: res.clients })
                    .then((clients) => {
                      if (clients && clients.length > 0) {
                        setCustomers(dispatch, clients);
                      }
                    })
                    .catch((err) => console.log("error", err));
                }
              }
            })
            .catch((err) => console.log("error", err));

          if (!allStatistics && tokens.mfa) {
            getAllStatistics()
              .then((res) => {
                if (res && res.results && res.results.length > 0) {
                  const objectToSave = { ...res.results[0] };
                  counterStealers().then((counters) => {
                    if (counters && counters.completeStealers) {
                      Object.assign(objectToSave, { completeStealers: counters.completeStealers });
                    }
                    if (counters && counters.incompleteStealers) {
                      Object.assign(objectToSave, {
                        incompleteStealers: counters.incompleteStealers,
                      });
                    }
                    if (counters && counters.stealersToHandle) {
                      Object.assign(objectToSave, {
                        stealersToHandle: counters.stealersToHandle,
                      });
                    }
                    setAllStatistics(dispatch, objectToSave);
                  });
                }
              })
              .catch((err) => console.log("error", err));
          }

        } else {
          localStorage.removeItem("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("userid");
          localStorage.removeItem("mfa");
          window.location.href = "/login";
        }
      });
    }
  }, [controller.user]);

  useEffect(() => {
    if (controller.user && controller.user.language) {
      i18next.changeLanguage(controller.user.language);
    }
  }, [controller.user]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    usersPresent();

    // Check for the demo mode
    if (process.env.REACT_APP_ENV === "demo") {
      setDemoAlert(true);
    }
  }, []);

  useEffect(() => {
    if (
      !customers ||
      customers.length < 1 ||
      !customers.some((cli) => cli.service && cli.service.includes("ransomware"))
    ) {
      const newRoutes = [].concat(routes);
      const indexToRemove = newRoutes.findIndex((rou) => rou.key === "ransomware");
      if (indexToRemove >= 0) {
        newRoutes.splice(indexToRemove, 1);
        setUserRoutes(newRoutes);
      }
    } else {
      setUserRoutes(routes);
    }
  }, [customers]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  let admin = false;

  if (user && user.role === "admin") {
    admin = true;
  }

  // Close demo alert btn
  const handleCloseBtn = () => {
    setDemoAlert(false);
  };

  return (
    <>
      <I18nextProvider i18n={i18next}>
        {isPageReady ? (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <TokenExpiredPopup />
            {layout === "dashboard" && (
              <>
                {demoAlert ? <DemoAlert handleCloseBtn={handleCloseBtn} /> : null}
                <Sidenav
                  color={sidenavColor}
                  brand={!miniSidenav ? brandDark : brandSmall}
                  brandName="Defenda"
                  routes={admin ? routesAdmin : userRoutes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </>
            )}
            <Routes>
              {getRoutes(admin ? routesAdmin : userRoutes)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </ThemeProvider>
        ) : null}
      </I18nextProvider>
    </>
  );
}
