import React, { useEffect, useState } from "react";
import moment from "moment";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// APIs
import getSingleDataBreach from "api/dataBreach/getSingleDataBreach";

const DataBreachDetails = () => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await getSingleDataBreach(id);
      setData(response);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Title */}
      <h2 className="page-title">{data && data.shortDescription}</h2>

      {/* Box */}
      <div className="boxes-border-radius" style={{ background: "white", padding: "15px" }}>
        {data ? (
          <>
            <dl
              style={{
                fontSize: "16px",
                display: "flex",
                gap: "20px",
              }}
            >
              <div style={{ flex: 1 }}>
                <dt style={{ fontWeight: "600" }}>{t("dataBreach.name")}</dt>
                <dd style={{ marginBottom: "15px" }}>{data.threatActor?.name || "N/A"}</dd>

                <dt style={{ fontWeight: "600" }}>{t("dataBreach.date")}</dt>
                <dd style={{ marginBottom: "15px" }}>{moment(data.date).format("DD/MM/YYYY")}</dd>

                <dt style={{ fontWeight: "600" }}>{t("dataBreach.description")}</dt>
                <dd style={{ marginBottom: "15px" }}>{data.description}</dd>
              </div>

              <div style={{ flex: 1 }}>
                <dt style={{ fontWeight: "600" }}>{t("dataBreach.intelligence")}</dt>
                <dd style={{ marginBottom: "15px" }}>{data.intelligence}</dd>

                <dt style={{ fontWeight: "600" }}>{t("dataBreach.columns")}</dt>
                <dd style={{ marginBottom: "15px", display: "flex", gap: "5px" }}>
                  {data?.columns?.length > 0
                    ? data?.columns?.map((item, j) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={j}
                          style={{
                            fontSize: "14px",
                            background: "lightgray",
                            borderRadius: "35px",
                            textAlign: "center",
                            marginBottom: "3px",
                            width: "min-content",
                            padding: "1px 5px",
                          }}
                        >
                          {item}
                        </div>
                      ))
                    : null}
                </dd>
              </div>
            </dl>
          </>
        ) : null}
      </div>

      {/* Table */}
      {data?.results?.length > 0 ? (
        <div className="table_users_list" style={{ maxWidth: "900px", marginTop: "20px" }}>
          <table className="table-content" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                {data?.columns?.map((column) => (
                  <th key={column}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.results[0]?.rows.map((row) => (
                <tr key={row}>
                  {data?.columns.map((column) => (
                    <td key={column}>{row[column]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </DashboardLayout>
  );
};

export default DataBreachDetails;
