const phishing = {
  en: {
    translation: {
      url: "URL",
      path: "Path",
      first_seen: "First seen",
      last_seen: "Last seen",
      victim_name: "Victim",
      victim_nation: "Victim country",
      geo_location: "Location",
      details: "Details",
      nameservers: "Nameservers",
      ip: "IP",
      asn: "ASN",
      tls_validity: "TLS certificate validity",
      dom_hash: "DOM hash",
      text_hash: "Text hash",
      title: "Title",
      description: "Description",
      intelligence: "Intelligence",
      favicon: "Favicon",
      screenshot: "Screenshot",
      page_description: "Impersonation or imitation of your brand for malicious purposes.",
      website_status: "Website status",
      website_status_active: "Active",
      website_status_in_progress: "In Deactivation",
      website_status_disabled: "Deactivated",
    },
  },
  it: {
    translation: {
      url: "URL",
      path: "Percorso",
      first_seen: "Primo rilevamento",
      last_seen: "Ultimo rilevamento",
      victim_name: "Vittima",
      victim_nation: "Paese vittima",
      geo_location: "Localizzazione",
      details: "Dettagli",
      nameservers: "Nameservers",
      ip: "IP",
      asn: "ASN",
      tls_validity: "Validità certificato TLS",
      dom_hash: "Hash DOM",
      text_hash: "Hash testo",
      title: "Titolo",
      description: "Descrizione",
      intelligence: "Intelligence",
      favicon: "Favicon",
      screenshot: "Screenshot",
      page_description: "Impersonificazione o imitazione del vostro brand a fini malevoli",
      website_status: "Stato sito",
      website_status_active: "Attivo",
      website_status_in_progress: "In Disattivazione",
      website_status_disabled: "Disattivato",
    },
  },
};

export default phishing;
